<template>
  <section class="faq">
    <div class="med-head">
      <button 
      :class="showfaq?'active':''"
      v-on:click="toggleShow(true)"
      >
      SYARAT & KETENTUAN
      </button>
      <button 
      :class="!showfaq?'active':''"
      v-on:click="toggleShow(false)"
      >
      KEBIJAKAN PRIVASI
      </button>
    </div>
      <div v-if="showfaq">
        <h3>Syarat dan Ketentuan</h3>
        <div class="dis">Selamat datang di layanan game Rally Hoki. 
        Harap baca dengan seksama syarat dan ketentuan penggunaan berikut sebelum mengunduh, mengakses, atau menggunakan game kami. Dengan mengunduh, mengakses, atau menggunakan game ini, Anda setuju untuk terikat oleh syarat dan ketentuan ini. Jika Anda tidak setuju dengan syarat dan ketentuan ini, mohon untuk tidak menggunakan game ini.
        </div>
        <ol>
          <li>Lisensi Penggunaan 
            <ol>
              <li>Dengan mematuhi syarat dan ketentuan ini, kami memberikan Anda lisensi terbatas, tidak eksklusif, tidak dapat dipindahtangankan, dan dapat dicabut untuk mengunduh, mengakses, dan menggunakan game kami hanya untuk tujuan pribadi dan hiburan. </li>
                <li>Anda setuju untuk tidak mereproduksi, mendistribusikan, mengalihbahasakan, atau membuat karya turunan dari game ini tanpa izin tertulis dari pemilik lisensi kami.</li>
            </ol>
          </li>
          <li>Batasan Umur 
            <ol>
              <li>Anda harus berusia minimal 13 tahun untuk menggunakan game ini. Jika Anda di bawah usia 13 tahun, Anda harus mendapatkan izin dari orang tua atau wali hukum Anda sebelum menggunakan game ini.</li>
            </ol>
          </li>
          <li>Akun Pengguna
            <ol>
              <li>Anda dapat diminta untuk membuat akun pengguna untuk menggunakan beberapa fitur dalam game kami. Pastikan informasi yang Anda berikan akurat, lengkap, dan diperbarui secara berkala.</li>
              <li>Anda bertanggung jawab atas menjaga keamanan informasi akun Anda dan setiap aktivitas yang terjadi melalui akun tersebut.</li>
            </ol>
          </li>
          <li>Konten Pengguna 
            <ol>
              <li>Dalam beberapa kasus, game kami mungkin kedepannya memungkinkan Anda untuk mengirimkan atau berbagi konten pengguna, seperti ulasan, komentar, atau kreasi in-game. Dengan mengirimkan konten pengguna, Anda memberikan kami lisensi global, tidak eksklusif, tanpa royalti, untuk menggunakan, mereproduksi, mengubah, mengadaptasi, menerbitkan, mendistribusikan, dan menampilkan konten tersebut. </li>
              <li>Anda setuju untuk tidak mengirimkan konten yang melanggar hukum, melanggar hak kekayaan intelektual, mengandung unsur kebencian, mengandung virus atau program berbahaya lainnya, atau merugikan pihak lain.</li>
            </ol>
          </li>
          <li>Pembayaran 
            <ol>
              <li>Game kami mungkin memiliki fitur pembelian dalam aplikasi. Dengan melakukan pembelian dalam aplikasi, Anda setuju untuk membayar harga yang tercantum serta pajak yang berlaku. </li>
              <li>Semua pembayaran dalam aplikasi bersifat final dan tidak dapat dikembalikan, kecuali ditetapkan sebaliknya oleh hukum yang berlaku.</li>
            </ol>
          </li>
          <li>Pembaruan dan Perubahan
            <ol>
              <li>Kami berhak memperbarui, memodifikasi, atau menghentikan game kami kapan saja tanpa pemberitahuan sebelumnya. </li>
              <li>Anda setuju bahwa kami tidak akan bertanggung jawab atas kerugian atau kerusakan yang diakibatkan oleh pembaruan atau perubahan dalam game kami.
  </li>
            </ol>
          </li>
          <li>Tanggung Jawab
            <ol>
              <li>Game kami disediakan "apa adanya" dan "sebagaimana tersedia" tanpa jaminan apapun, baik secara tersurat maupun tersirat. Kami tidak menjamin bahwa game akan berjalan tanpa gangguan atau bebas dari kesalahan. </li>
              <li>Kami tidak bertanggung jawab atas kerugian, cedera, atau kerusakan yang timbul akibat penggunaan game ini, termasuk kerugian langsung, tidak langsung, insidental, atau konsekuensial.</li>
            </ol>
          </li>
          <li>Hukum yang Berlaku
            <ol>
              <li>Syarat dan ketentuan ini diatur oleh hukum yang berlaku di wilayah hukum kami, yaitu di Indonesia.</li>
              <li>Setiap sengketa yang timbul dari atau terkait dengan game ini akan diselesaikan melalui arbitrase yang diatur oleh hukum yang berlaku di Indonesia.</li>
            </ol>
          </li>
        </ol>
        <div class="dis">Harap dicatat bahwa syarat dan ketentuan ini dapat berubah dari waktu ke waktu tanpa pemberitahuan sebelumnya. Dengan terus menggunakan game ini, Anda dianggap menerima perubahan tersebut. Jika Anda memiliki pertanyaan tentang syarat dan ketentuan ini, silakan hubungi tim dukungan kami di <a href="tel:02122732014">02122732014</a>. Terima kasih telah menggunakan game <b>Rally Hoki!</b></div>
      </div>
      <div v-else class="police">
        <h3>Kebijakan Privasi</h3>
        <h4>Pendahuluan</h4>
        <p>Kebijakan privasi ini ("Kebijakan") menjelaskan bagaimana PT.Sinergi Bestama Indonesia(SBI) memproses, mengumpulkan, menggunakan, dan membagikan data pribadi ketika menggunakan aplikasi <b>Rally Hoki</b> ("Aplikasi"). Mohon baca informasi berikut ini dengan seksama untuk memahami praktik-praktik kami terkait data pribadi Anda dan bagaimana kami akan memproses data.</p>
        <h4>Tujuan Pemrosesan</h4>
        <p class="quis">
          <b
            v-on:click="toggleQuiz(1)"
          >
            Apa yang dimaksud dengan data pribadi?</b></p>
        <div v-if="showQuis==1">
          <p>Kami mengumpulkan informasi tentang Anda dalam berbagai bentuk, termasuk data pribadi. Sebagaimana digunakan dalam Kebijakan ini, "data pribadi" adalah sebagaimana didefinisikan dalam Peraturan Perlindungan Data Umum, ini mencakup informasi apa pun yang, baik secara terpisah maupun bersama dengan informasi lain yang kami proses tentang Anda, mengidentifikasi Anda sebagai individu, termasuk, misalnya, nama, alamat pos, alamat email, dan nomor telepon Anda.</p>
        </div>
        <p class="quis">
          <b
            v-on:click="toggleQuiz(2)"
          >
            Mengapa kami membutuhkan data pribadi Anda?</b></p>
        <div v-if="showQuis==2">
            <p>Kami hanya akan memproses data pribadi Anda sesuai dengan undang-undang perlindungan data dan privasi yang berlaku. Kami memerlukan data pribadi tertentu untuk memberi Anda akses ke Aplikasi. Jika Anda mendaftar pada kami, Anda akan diminta untuk mencentang untuk menyetujui memberikan informasi ini untuk mengakses layanan kami, membeli produk kami, atau melihat konten kami. Persetujuan ini memberi kami dasar hukum yang kami perlukan berdasarkan hukum yang berlaku untuk memproses data Anda. Anda memiliki hak untuk menarik persetujuan tersebut kapan saja. Jika Anda tidak menyetujui penggunaan data pribadi Anda oleh kami sesuai dengan Kebijakan ini, mohon untuk tidak menggunakan Aplikasi kami.</p>
        </div>
        <p class="quis">
          <b
            v-on:click="toggleQuiz(3)"
          >
            Mengumpulkan Data Pribadi Anda</b></p>
        <div v-if="showQuis==3">
        <p>Kami mengumpulkan informasi tentang Anda dengan cara-cara berikut:</p>
        <p>Informasi yang Anda Berikan kepada Kami. Ini termasuk:</p>
        <ul>
          <li>data pribadi yang Anda berikan ketika Anda mendaftar untuk menggunakan Aplikasi kami, termasuk nama, alamat pos, alamat email, nomor telepon, nama pengguna, kata sandi, dan informasi demografis (seperti jenis kelamin Anda);</li>
          <li>data pribadi yang mungkin terkandung dalam video, komentar, atau kiriman lain yang Anda unggah atau kirimkan ke Aplikasi;</li>
          <li>data pribadi yang Anda berikan sehubungan dengan program hadiah kami dan promosi lain yang kami jalankan di Aplikasi;</li>
          <li>data pribadi yang Anda berikan saat Anda melaporkan masalah dengan Aplikasi kami atau saat kami memberikan dukungan pelanggan kepada Anda;</li>
          <li>data pribadi yang Anda berikan ketika Anda melakukan pembelian melalui Aplikasi kami; dan</li>
          <li>data pribadi yang Anda berikan ketika Anda berkorespondensi dengan kami melalui telepon, email, atau lainnya.</li>
        </ul>
        </div>
        <h4>Informasi yang Dikumpulkan Secara Otomatis.</h4>
        <p>Kami secara otomatis mencatat informasi tentang Anda dan komputer atau perangkat seluler Anda ketika Anda mengakses Aplikasi kami. Sebagai contoh, ketika mengunjungi Aplikasi kami, kami mencatat nama dan versi sistem operasi komputer atau perangkat seluler Anda, produsen dan model, jenis browser, bahasa browser, resolusi layar, Aplikasi yang Anda kunjungi sebelum menjelajahi Aplikasi kami, halaman yang Anda lihat, berapa lama Anda menghabiskan waktu di sebuah halaman, waktu akses, dan informasi mengenai penggunaan dan tindakan Anda pada Aplikasi kami. Kami mengumpulkan informasi ini tentang Anda dengan menggunakan cookie.</p>
        <h4>Pengambilan Keputusan dan Pembuatan Profil Otomatis.</h4>
        <p>Kami tidak menggunakan data pribadi Anda untuk tujuan pengambilan keputusan otomatis. Namun, kami dapat melakukannya untuk memenuhi kewajiban yang dibebankan oleh hukum, dalam hal ini kami akan memberi tahu Anda tentang pemrosesan tersebut dan memberi Anda kesempatan untuk mengajukan keberatan.</p>
        <h3>Cookie</h3>
        <h4>Apa yang dimaksud dengan cookie?</h4>
        <p>Kami dapat mengumpulkan informasi dengan menggunakan "cookie". Cookie adalah file data kecil yang disimpan di hard drive komputer atau perangkat seluler Anda oleh Aplikasi. Kami dapat menggunakan cookie sesi (yang akan kedaluwarsa setelah Anda menutup browser web Anda) dan cookie tetap (yang tetap berada di komputer atau perangkat seluler Anda hingga Anda menghapusnya) untuk memberi Anda pengalaman yang lebih pribadi dan interaktif di Aplikasi kami.</p>
        <p>Kami menggunakan dua kategori cookie yang luas: (1) cookie pihak pertama, yang disajikan langsung oleh kami ke komputer atau perangkat seluler Anda, yang hanya digunakan oleh kami untuk mengenali komputer atau perangkat seluler Anda saat mengunjungi kembali Aplikasi kami; dan (2) cookie pihak ketiga, yang dilayani oleh penyedia layanan di Aplikasi kami, dan dapat digunakan oleh penyedia layanan tersebut untuk mengenali komputer atau perangkat seluler Anda saat mengunjungi Aplikasi lain.</p>
        <h4>Menonaktifkan cookie</h4>
        <p>Anda biasanya dapat menghapus atau menolak cookie melalui pengaturan browser Anda. Untuk melakukannya, ikuti petunjuk yang disediakan oleh browser Anda (biasanya terletak di dalam fasilitas "pengaturan", "bantuan", "alat", atau "edit"). Banyak browser yang diatur untuk menerima cookie sampai Anda mengubah pengaturan Anda.</p>
        <p>Jika Anda tidak menerima cookie kami, Anda mungkin akan mengalami ketidaknyamanan dalam penggunaan Aplikasi kami. Sebagai contoh, kami mungkin tidak dapat mengenali komputer atau perangkat seluler Anda dan Anda mungkin perlu masuk setiap kali Anda mengunjungi Aplikasi kami.</p>
        <h4>Periklanan</h4>
        <p>Kami dapat menggunakan perusahaan lain untuk menayangkan iklan pihak ketiga ketika Anda mengunjungi dan menggunakan Aplikasi. Perusahaan-perusahaan ini dapat mengumpulkan dan menggunakan informasi aliran klik, jenis browser, waktu dan tanggal, subjek iklan yang diklik atau digulir selama kunjungan Anda ke Aplikasi dan Aplikasi lain untuk menyediakan iklan tentang barang dan jasa yang mungkin menarik bagi Anda. Perusahaan-perusahaan ini biasanya menggunakan teknologi pelacakan untuk mengumpulkan informasi ini. Penggunaan teknologi pelacakan oleh perusahaan lain tunduk pada kebijakan privasi mereka sendiri.</p>
        <h4>Menggunakan Data Pribadi Anda</h4>
        <p>Kami dapat menggunakan data pribadi Anda sebagai berikut:</p>
        <ul>
          <li>untuk mengoperasikan, memelihara, dan meningkatkan Aplikasi, produk, dan layanan kami;</li>
          <li>untuk mengelola akun Anda, termasuk untuk berkomunikasi dengan Anda mengenai akun Anda, jika Anda memiliki akun di Aplikasi kami;</li>
          <li>untuk mengoperasikan dan mengelola program hadiah kami dan promosi lain yang Anda ikuti di Aplikasi kami;</li>
          <li>untuk menanggapi komentar dan pertanyaan Anda dan untuk menyediakan layanan pelanggan;</li>
          <li>untuk mengirimkan informasi termasuk pemberitahuan teknis, pembaruan, peringatan keamanan, dan pesan dukungan dan administratif;</li>
          <li>dengan persetujuan Anda, untuk mengirimi Anda email pemasaran tentang promosi yang akan datang, dan berita lainnya, termasuk informasi tentang produk dan layanan yang ditawarkan oleh kami dan afiliasi kami. Anda dapat memilih untuk tidak menerima informasi tersebut kapan saja: email pemasaran tersebut akan memberi tahu Anda cara "memilih untuk tidak menerima". Harap diperhatikan, meskipun Anda memilih untuk tidak menerima email pemasaran, kami masih dapat mengirimkan email non-pemasaran kepada Anda. Email nonpemasaran mencakup email tentang akun Anda dengan kami (jika Anda memilikinya) dan urusan bisnis kami dengan Anda;</li>
        </ul>
        <h4>Membagikan Data Pribadi Anda</h4>
        <p>Kami dapat membagikan data pribadi Anda sebagai berikut:</p>
        <ul>
          <li>Pihak Ketiga yang Ditunjuk oleh Anda. Kami dapat membagikan data pribadi Anda dengan pihak ketiga di mana Anda telah memberikan persetujuan untuk melakukannya.</li>
          <li>Penyedia Layanan Pihak Ketiga Kami. Kami dapat membagikan data pribadi Anda dengan penyedia layanan pihak ketiga kami yang menyediakan layanan seperti analisis data, pemrosesan pembayaran, teknologi informasi dan penyediaan infrastruktur terkait, layanan pelanggan, pengiriman email, audit, dan layanan serupa lainnya.</li>
          <li>Situs Pihak Ketiga</li>
        </ul>
        <p>Aplikasi kami mungkin berisi tautan ke Aplikasi dan fitur pihak ketiga. Kebijakan ini tidak mencakup praktik privasi pihak ketiga tersebut. Pihak ketiga ini memiliki kebijakan privasi mereka sendiri dan kami tidak bertanggung jawab atau berkewajiban atas Aplikasi, fitur, atau kebijakan mereka. Harap baca kebijakan privasi mereka sebelum Anda mengirimkan data apa pun kepada mereka.</p>
        <h4>Konten Buatan Pengguna</h4>
        <p>Anda dapat membagikan data pribadi kepada kami ketika Anda mengirimkan konten yang dibuat oleh pengguna ke Aplikasi kami, termasuk melalui program hadiah, forum, papan pesan, dan Aplikasi di Aplikasi kami. Harap diperhatikan bahwa setiap informasi yang Anda kirimkan atau ungkapkan pada Aplikasi kami akan menjadi informasi publik, dan akan tersedia bagi pengguna lain dari Aplikasi kami dan masyarakat umum. Kami menghimbau Anda untuk sangat berhati-hati ketika memutuskan untuk mengungkapkan data pribadi Anda, atau informasi lainnya, pada Aplikasi kami. Data pribadi dan informasi lain tersebut tidak akan bersifat pribadi atau rahasia setelah dipublikasikan di Aplikasi kami.</p>
        <p>Jika Anda memberikan umpan balik kepada kami, kami dapat menggunakan dan mengungkapkan umpan balik tersebut di Aplikasi kami, asalkan kami tidak mengaitkan umpan balik tersebut dengan data pribadi Anda. Jika Anda telah memberikan persetujuan Anda untuk melakukannya, kami dapat memposting nama depan dan nama belakang Anda bersama dengan umpan balik Anda di Aplikasi kami. Kami akan mengumpulkan informasi apa pun yang terkandung dalam umpan balik tersebut dan akan memperlakukan data pribadi di dalamnya sesuai dengan Kebijakan ini.</p>
        <h4>Keamanan</h4>
        <p>Kami berusaha untuk menggunakan langkah-langkah organisasi, teknis, dan administratif yang wajar untuk melindungi data pribadi dalam organisasi kami. Sayangnya, tidak ada sistem transmisi atau penyimpanan yang dapat dijamin sepenuhnya aman, dan transmisi informasi melalui Internet tidak sepenuhnya aman. Jika Anda memiliki alasan untuk meyakini bahwa interaksi Anda dengan kami tidak lagi aman (misalnya, jika Anda merasa bahwa keamanan akun apa pun yang Anda miliki dengan kami telah disusupi), segera beri tahu kami tentang masalah tersebut dengan menghubungi kami.</p>
        <h4>Keluhan</h4>
        <p>Kami berkomitmen untuk menyelesaikan keluhan apa pun tentang pengumpulan atau penggunaan data pribadi Anda. Jika Anda ingin mengajukan keluhan terkait Kebijakan ini atau praktik kami terkait data pribadi Anda, silakan hubungi kami. Kami akan membalas keluhan Anda sesegera mungkin dan dalam hal apa pun, dalam waktu 30 hari. Kami berharap dapat menyelesaikan keluhan yang disampaikan kepada kami, namun jika Anda merasa bahwa keluhan Anda belum terselesaikan dengan baik, Anda berhak untuk menghubungi otoritas pengawas perlindungan data setempat.</p>
        <h4>Informasi Kontak</h4>
        <p>Kami menerima komentar atau pertanyaan Anda tentang Kebijakan ini. Anda dapat menghubungi kami di <a href="tel:02122732014">02122732014</a></p>
      </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
  import { mapState } from 'vuex' 
  export default {
    name: 'Tnc',
    data() {
      return {
        titleHeader: "Syarat & Ketentuan",
        showToken: false,
        showBg: true,
        showfaq: true,
        showQuis:0,
      };
    },
    mounted() {
      this.showfaq= true
    },
    computed: {
      ...mapState({
        coin: 'coin',
      })
    },
    methods: {
      goTo(page) {
          this.$router.push({ name: page });
      },
      toggleShow(s){
        if(this.showfaq!==s)this.showfaq=s;
      },
      toggleQuiz(s){
        if(s==this.showQuis){
          this.showQuis=0
        }else{
          this.showQuis=s;
        }
        
      }
    }
  }
  </script>

<style>
body {
    background-color: white;
}
</style>
<style scoped>
.police .quis b{
    color: #2675f7 !important;
    font-weight: 500 !important;
    background: #ffffff1c;
    display: block;
    padding: 10px 14px;
  }
  .faq{
    text-transform: none;
    text-align: left;
    padding: 10px;
    color: #ffffff;
    text-align: justify;
    position: relative;
  }
  .faq b{
    color:#fff;
    font-weight: 400;
  }
  .faq h3 {
      font-size: 1.35rem;
      color: #fff;
      border-bottom: 1px solid #8688f838;
  }
  .faq h4{
    font-size: 1.2rem;
    color: #fff;
  }
ol {
  counter-reset: item;
  margin-block-start: 0.3em !important;
  padding-inline-start: 10px;
}
ol li{ 
  display: block;
 }
 ol li:before { 
  content: counters(item, ".") ". "; 
  counter-increment: item;
  font-weight:bold;
  color: #fff !important;

}
.med-head{
  text-align: right;
  margin-bottom:40px;
}
.med-head button{
  color: #fff;
  font-size: 15px;
  background: #2675f7;
  padding: 5px 13px;
  margin: 0 7px;
  border: none;
  border-radius: 14px;
}
.med-head button.active{
  background: #00000042;
  color: #ffffff73;
}
</style>
