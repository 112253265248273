<template>
  <div :class="{ loader: true, fadeout: !isLoading }" class="justify-content-center d-flex flex-wrap">
    <b-img alt="Vue logo" :src="require('@/assets/loader.png')" class="align-self-center img-loader rotate"></b-img>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: ["isLoading"]
};
</script>

<style>
.rotate {
  width: 100px;
  animation: rotation 2s infinite linear;
}
.loader {
  background-color: #000;
  bottom: 0;
  color: white;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  /* padding-top: 10vh; */
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 2000;
}

.img-load {
    width: 40%;
    height: 40%;
}

.img-loader {
  width: 50px;
}

.fadeout {
  animation: fadeout 0.3s forwards;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
