import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutPlay from '@/components/LayoutPlay.vue'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'play',
    component: LayoutPlay,
    meta: {
      requiresAuth: true,
      page: 4,
    },
    children:[
      {
        path: '/play',
        name: 'play',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
        meta: {
          requiresAuth: true,
          page: 4,
        },
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      page: 1,
    }
  },
  {
    path: '/android',
    name: 'android',
    component: () => import(/* webpackChunkName: "login" */ '../views/Android.vue'),
    meta: {
      page: 2,
    }
  },
  {
    path: '/pid/:cmsisdn',
    name: 'pid',
    component: () => import(/* webpackChunkName: "login" */ '../views/Pid.vue'),
    meta: {
      page: 3,
    }
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import(/* webpackChunkName: "login" */ '../views/Loadingscreen.vue'),
    meta: {
      requiresAuth: true,
      page: 4,
    }
  },
  {
    path: '/info',
    name: 'info',
    component: () => import(/* webpackChunkName: "login" */ '../views/Info.vue'),
    meta: {
      page: 5,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (/*to, from, savedPosition*/) {
    // console.log(to, from, savedPosition)
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isUser || store.state.user) {
      next()
      return
    }
    next({path:'/login'})
  } else {
    next()
  }
})

export default router
